import styled from "styled-components";

export const StyledIOSPWAContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  align-items: center;

  img{
    width: 90%;
  }
`;

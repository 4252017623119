var selector = 1
var GlobalStyleDefault = {}

if (selector === 1) {
  
  GlobalStyleDefault = {
    colors: {
      primary: '#F692BE44',
      primarystrong: '#F692BE',
      secondary: '#8F7EE966',
      secondarystrong: '#8F7EE9',
      tertiary: '#F76DBE66',
      tertiarystrong: '#F76DBE',
      background: '#f2f2f2',
      offwhite: '#FAEED0',
      backgroundwhite: '#fff',
      textwhite: '#fff',
      text: '#000',
      cardOutline: "#1d743e",
      cardOutline2: "#5B388F",
      cardOutline3: "#FD68B9",
      gradient: 'linear-gradient(180deg,#8F7EE9d0, #F692BEd0, #F76DBEd0)',
      gradientheader: 'linear-gradient(90deg, rgba(255,255,255,1) 23%, rgba(246,146,190,1) 77%)',
      plantgreen: "#1d743e",
      plantgreenblur: "#1d743ecc"
      
      
      
      
    },
    
      fontSize: {
        smaller: "1rem",
        small: "1.2rem",
        medium: "1.4rem",
        big: "1.6rem",
        bigger: "1.8rem",
        title: "2rem"

      },
      shadows: {
        small: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        medium: '0px 4px 10px rgba(246, 146, 190, 0.8)',
        large: '0px 8px 16px rgba(0, 0, 0, 0.3)',
        paginationButton: '0px 4px 6px 2px rgba(0, 0, 0, 0.3)',
        card: '10px 18px 16px rgba(0, 0, 0, 0.6)',
        innerButton: '0px 3px 4px 2px rgba(0, 0, 0, 0.6)',
        cardMainBlog: '0px 10px 15px 6px rgba(0, 0, 0, 0.6)',
        activeCategorie: '4px 4px 2px rgba(147, 132, 215, 0.6)',
        banner: '10px 18px 16px rgba(0, 0, 0, 0.6)',
        footer: '0px -6px 16px rgba(0, 0, 0, 0.3)',
        
      },
    }
  }else if (selector === 2) {
  
    GlobalStyleDefault = {
      colors: {
        primary: '#1f716255',
        primarystrong: '#1f7162',
        secondary: '#3d836166',
        secondarystrong: '#3d8361',
        tertiary: '#008b6966',
        tertiarystrong: '#008b69',
        background: '#f2f2f2',
        offwhite: '#FAEED0',
        backgroundwhite: '#fff',
        textwhite: '#fff',
        text: '#000',
        cardOutline: "#1d743e",
        cardOutline2: "#5B388F",
        cardOutline3: "#FD68B9",
        gradient: 'linear-gradient(180deg,#8F7EE9d0, #F692BEd0, #F76DBEd0)',
        gradientheader: 'linear-gradient( 109.6deg,  rgba(255,255,255,1) 11.2%, #1f716255 91.1% );',
        plantgreen: "#1d743e",
        plantgreenblur: "#1d743ecc"
        
        
        
        
      },
      
        fontSize: {
          smaller: "1rem",
          small: "1.2rem",
          medium: "1.4rem",
          big: "1.6rem",
          bigger: "1.8rem",
          title: "2rem"
  
        },
        shadows: {
          small: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          medium: '0px 4px 10px rgba(246, 146, 190, 0.8)',
          large: '0px 8px 16px rgba(0, 0, 0, 0.3)',
          paginationButton: '0px 4px 6px 2px rgba(0, 0, 0, 0.3)',
          card: '10px 18px 16px rgba(0, 0, 0, 0.6)',
          innerButton: '0px 3px 4px 2px rgba(0, 0, 0, 0.6)',
          cardMainBlog: '0px 10px 15px 6px rgba(0, 0, 0, 0.6)',
          activeCategorie: '4px 4px 2px rgba(147, 132, 215, 0.6)',
          banner: '10px 18px 16px rgba(0, 0, 0, 0.6)',
          footer: '0px -6px 16px rgba(0, 0, 0, 0.3)',
          
        },
      }
    }

  
  export default GlobalStyleDefault;
import styled from "styled-components";

export const StyledCalendarAPIBody = styled.div`
  height: 100%;
  background-color: transparent;
  margin: 0;
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  justify-content: start;
  align-items: center;

  #inputCreationEvent {
    width: 100%;
    margin: 5px;
  }
  #buttonCreationContainer {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    
  }

  
`;

import styled from "styled-components";
import GlobalStyleDefault from "../../GlobalStyles";

export const StyledLandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: fit-content;

 
  
  
  



`;
